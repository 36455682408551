export const ProjectsList = [
  {
    title: "NASA APOD",
    desc: "Next.js app showcasing NASA's Astronomy Picture of the Day (APOD)",
    image: "./assets/nasa-apod-thumbnail.png",
    preview: "https://nasa-apod-ns.vercel.app",
    github: "https://github.com/navjotSingh2000/nasa-apod",
  },
  {
    title: "Joke Notification Scheduler",
    desc: "React native cross platform mobile app to get motivational quotes as notification",
    image: "./assets/uplift-thumbnail.png",
    preview: "./assets/uplift-thumbnail.png",
    github: "https://github.com/navjotSingh2000/uplift",
  },
  {
    title: "People in space",
    desc: "Golang powered web app to display the list of people who are currently in the space",
    image: "./assets/people-in-space-thumbnail.png",
    preview: "./assets/people-in-space-thumbnail.png",
    github: "https://github.com/navjotSingh2000/golang-people-in-space",
  },
  {
    title: "URL Shortener",
    desc: "Spring Boot REST API to shorten a URL",
    image: "./assets/url-shortener-thumbnail.png",
    preview: "./assets/url-shortener-thumbnail.png",
    github: "https://github.com/navjotSingh2000/url-shortener-spring-boot",
  },
  {
    title: "Bored App",
    desc: "Shows activities to do when you are feeling bored",
    image: "./assets/BoredApp-both-thumbnail.png",
    preview: "./assets/BoredApp-iphone.gif",
    github: "https://github.com/navjotSingh2000/BoredApp",
  },
  {
    title: "Furniture Store Website",
    desc: "Online store for furniture shopping built using Vanilla JS",
    image: "./assets/furniture-store-website-thumbnail.png",
    preview: "https://youtu.be/84NI2k-HvH8",
    github: "https://github.com/navjotSingh2000/furniture-store-website",
  },
  {
    title: "Weather App",
    desc: "Shows the current weather of your current location",
    image: "./assets/weatherApp-thumbnail.png",
    preview: "./assets/WeatherApp-android.gif",
    github: "https://github.com/navjotSingh2000/WeatherApp",
  },
  {
    title: "Portfolio Website",
    desc: "Shows my recent personal projects and information about me",
    image: "./assets/portfolio-site-thumbnail.png",
    preview: "https://navjotsingh.net",
    github: "https://github.com/navjotSingh2000/portfolio-site",
  },
];
